<template>
  <AtomsModal @close="closeFastPayPopup" class="pay-modal">
    
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit
      @submit="onSubmit"
      v-model="forms"
      type="form"
      :actions="false"
      :incomplete-message="false"
      #="{ state, value }"
      form-class="fast-pay-form"
    >
      <div class="pay-modal-title-box">
        <h2 class="pay-modal-title">{{ $t('product.pay_modal.title') }}</h2>
        <DisputeDropdown />
      </div>
      <div class="card-product">
        <div class="img-box">
          <NuxtImgC
            :src="currentProduct?.image_id"
            alt=""
            :fallback="fallbackImg"
            sizes="166px lg:130px"
          />
        </div>
        <div class="text-box">
          <p class="title"> {{ currentProduct?.name }}</p>
          <p
            class="description"
            ref="clampNode"
            :class="{ clamp: isClamp }"
          >
            {{ currentProduct?.description }}
          </p>
          <div class="text-box-btns">
            <button
              type="button"
              class="button-clamp"
              @click="isClampToglle"
              v-if="clampButtonVisible"
            >
              {{ isClamp ? $t('product.pay_modal.show_full_description') : $t('product.pay_modal.hide') }}
            </button>
            <p class="text-box-quantity">
              {{ t('interface.in_stock') }}
              {{ currentProduct?.quantity }}
            </p>
          </div>
        </div>
      </div>
      <div class="price-input-box">
        <div class="price-box">
          <p>{{ $t('product.pay_modal.price_per_item') }}</p>
          <div class="text-box">
            <p class="price">
              {{ getCurrentCurrencyString(currentProduct?.discount_price ?? currentProduct?.price ?? 0) }}
            </p>
            <p
              v-if="currentProduct?.discount_price"
              class="old-price"
            >
              {{ getCurrentCurrencyString(currentProduct.price) }}
            </p>
          </div>
        </div>
        <FormKit
          outer-class="price-input-box-count"
          :value="currentAmount"
          name="count"
          @input="(value) => currentAmount = value"
          validation="required:trim"
          :min="1"
          :max="currentProduct?.quantity"
          type="counter"
          :label="$t('interface.count')"
        />
        <div class="payments-chosen-price-container col-span-full">
          <p v-if="selectedPayInfo?.min_deposit_amount" class="payments-chosen-price-container-item">
            {{ $t('payments.min_payment') }}:
            <span>{{ getCurrentCurrencyString(selectedPayInfo.min_deposit_amount) }}</span>
          </p>
          <p class="payments-chosen-price-container-item">
            {{ $t('payments.commission') }}:
            <span>
							<template v-if="selectedPayInfo?.percent_fee">{{ selectedPayInfo.percent_fee }}%, &nbsp</template>
							<template v-if="selectedPayInfo?.fix_fee">
                {{ $t('payments.min_commission') }}: &nbsp{{ getCurrentCurrencyString(selectedPayInfo.fix_fee) }}</template>
						</span>
          </p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="form-inputs">
        <!-- <FormKit type="selectC" validation="required" :label="t('payments.choosing_country')" :options="countrys" :value="countrys?.[0].value" /> -->
        <FormKit
          outer-class="lg-show"
          name="payment"
          type="toggleButtons"
          validation="required"
          :label="t('payments.type_payment')"
          :options="paymentsOptions"
        />
        <FormKit
          outer-class="xs-show"
          name="payment"
          type="selectC"
          validation="required"
          :label="t('payments.type_payment')"
          :options="paymentsOptions"
        />
        <FormKit
          outer-class="lg-show"
          name="payment_method"
          type="toggleButtons"
          validation="required"
          :label="t('payments.payment_option')"
          :options="payInfoOptions"
        />
        <FormKit
          outer-class="xs-show"
          name="payment_method"
          type="selectC"
          validation="required"
          :label="t('payments.payment_option')"
          :options="payInfoOptions"
        />
        <FormKit
          name="email"
          type="email"
          validation="required:trim | email | ?emailValidation"
          :label="t('forms.email_placeholder')"
          :placeholder="$t('forms.email_placeholder')"
          :validation-rules="{ emailValidation }" :validation-messages="{ emailValidation: '' }"
        >
          <template #message="{ messages, classes }">
            <template v-for="message in messages">
              <li
                v-if="message.meta.messageKey !== 'emailValidation'"
                :class="classes.message"
                :data-message-type="message.type"
              >
                {{ message.value }}
              </li>
            </template>
          </template>
          <template #suffix="{ classes }">
            <p
              v-if="emailValid && forms?.email"
              :class="classes.suffixIcon"
              class="email-valid-suffix lg-show"
            >
              {{ t('interface.user_found') }}
            </p>
            <img
              v-if="emailValid && forms?.email"
              :class="classes.suffixIcon"
              class="email-valid-suffix-img xs-show"
              src="/assets/images/svg/check-green.svg"
            >
          </template>
        </FormKit>
        <FormkitPassword
          v-if="emailValid && value?.email" name="password"
          :label="$t('auth.password_label')"
          :placeholder="$t('auth.password_placeholder')"
          validation="required:trim"
        >
        </FormkitPassword>
      </div>
      <!-- <div class="discounts">
        <table>
          <tbody>
            <tr class="discounts-item">
              <td class="label">{{ $t('interface.discount') }}</td>
              <td class="value">0,00 ₽</td>
            </tr>
            <tr class="discounts-item">
              <td class="label">от 250 шт</td>
              <td class="value">14,50 ₽ / шт</td>
            </tr>
            <tr class="discounts-item">
              <td class="label">от 500 шт</td>
              <td class="value">14,00 ₽ / шт</td>
            </tr>
            <tr class="discounts-item">
              <td class="label">от 1000 шт</td>
              <td class="value">13,50 ₽ / шт</td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <!-- <div class="dispute-plashka">
        <p>{{ $t('product.pay_modal.dispute_fast_pay_title') }}</p>
        <span>
          <p>{{ $t('product.pay_modal.dispute_time') }}</p>
          <p>{{ $t('product.pay_modal.dispute_fast_pay_subtitle') }}</p>
        </span>
      </div> -->
      <div class="button-box">
        <button
          :class="{ 'btn-loaded': state.loading }"
          :disabled="((!value?.checkbox_1 || !value?.checkbox_2) && (!emailValid || !forms?.email)) || state.loading"
          class="btn btn-pay btn-secondary"
          type="submit"
        >
          {{ t('interface.buy_any_piece_for_any', [value?.count, getCurrentCurrencyString(totalPrice)]) }}
        </button>
        <button
          :disabled="state.loading"
          class="btn btn-light"
          type="button"
          @click="closeFastPayPopup"
        >
          {{ $t('product.pay_modal.cancel') }}
        </button>
      </div>
      <div
        v-if="!emailValid || !forms?.email"
        class="checkbox-container"
      >
        <FormKit
          :value="true"
          name="checkbox_1"
          type="checkbox"
        >
          <template #label="{ id, classes }">
            <label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a
                :href="`/${locale}/terms-of-use.pdf`" target="_blank">{{
                $t('forms.checkbox.terms_of_use')
              }}</a></label>
          </template>
        </FormKit>
        <FormKit name="checkbox_2" :value="true" type="checkbox">
          <template #label="{ id, classes }">
            <label :for="id" :class="classes.label">{{ $t('interface.i_agree') }} <a
                :href="`/${locale}/privacy-cookie-policy.pdf`"
                target="_blank">{{ $t('forms.checkbox.privacy_cookie_policy') }}</a></label>
          </template>
        </FormKit>
      </div>
    </FormKit>
</FormKitLazyProvider>

  </AtomsModal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import {usePayments} from '~/composables/payment/usePayments';
import {useUserAuth} from '~/composables/user/UseUserAuth';
import {detectDeviceAndRedirect} from '~/assets/functions';
const { isLoggedIn } = storeToRefs(useUserStore())

type Form = {
  payment_method?: null | { payment_method_type: string, id: string },
  payment?: null | string | number,
  amount?: number | null
  amount_commision?: number | null,
  email: null
  topUpAmount: number | null,
}

const {getPaymentMethods, fastPay} = usePayments()

const forms = ref<Form>({
  payment_method: null,
  payment: null,
  amount: null,
  amount_commision: null,
  email: null,
  topUpAmount: null,
})
const {getCurrentCurrencyString} = useCurrencyHelpers()
const {locale, t} = useI18n()

const {data: payments} = await useAsyncData(() => getPaymentMethods())
const paymentsOptions = computed(() => {
  return payments.value?.map(a => {
    return {
      value: a.name,
      label: a.name,
      icon: a.image_id
    }
  })
})

watch(paymentsOptions, (newValue) => {
  if (!newValue) return
  const _value = newValue?.find(a => a.value === forms.value?.payment)
  if (_value) return
  forms.value.payment = newValue?.find(a => true)?.value
}, {immediate: true})

const payInfoOptions = computed(() => {
  return payments.value?.find(a => a.name === forms.value?.payment)?.pay_info.map(a => {
    return {
      value: {payment_method_type: a.payment_method_type, id: a.name},
      label: a.name,
      icon: a.image_id
    }
  })
})

watch(payInfoOptions, (newValue) => {
  if (!newValue) return
  const _value = newValue?.find(a => a.value.id === forms.value?.payment_method?.id)
  if (_value) return
  forms.value.payment_method = newValue?.find(a => true)?.value
}, {immediate: true})

const selectedPayInfo = computed(() => {
  return payments.value?.find(a => a.name === forms.value?.payment)?.pay_info?.find(a => a.name === forms.value.payment_method?.id)
})

const {$toast} = useNuxtApp()
const {getUserMe, checkIfEmailExists, userLogin} = useUserAuth()
const localePath = useLocalePath()

const {closeFastPayPopup, currentFastPayProduct: currentProduct, currentFastPayProductCount} = useFastPayModal()
const {reachGoal} = useYm()
const onSubmit = async (form: { email: string, count: number, password?: string }) => {
  reachGoal('button_pay_product_payment')
  if (!currentProduct.value || !selectedPayInfo.value) return

  if (emailValid.value && form.password) {
    const res = await userLogin({email: form.email, password: form.password}).then(async (res) => {
      await getUserMe()
      return res
    }).catch(error => {
      $toast.error(error.data.message)
      return null
    })
    if (!res) return
  }

  const res = await fastPay({
    email: form.email,
    quantity: form.count,
    paymentMethodId: selectedPayInfo.value.id,
    productId: currentProduct.value.id,
    amount: totalPrice.value,
    topUpAmount: currentProduct?.value?.discount_price ?? currentProduct?.value?.price,
    options: {
      payment_method: selectedPayInfo.value.payment_method_type
    }
  }).catch((err) => {
    /* console.log(err.data.error.code === 401)
    if (err.data.error.code === 401) {
      $toast.warning(t('forms.already_registred'))
      const slugCookie = useCookie('slugOfProduct', {
        maxAge: 300,
        path: '/'
      })
      slugCookie.value = currentProduct.value?.slug
      navigateTo('/auth')
      closeFastPayPopup();
      return
    } */
    $toast.error(err.data.error.message)
  })
  if (!res) return
  if (emailValid.value) {
    if (res.hasBeenBalance) {
      $toast.success(t('forms.success'))
      closeFastPayPopup()
      return await navigateTo(localePath('profile'))
    }
    detectDeviceAndRedirect(res?.paymentUrl)
  }
  detectDeviceAndRedirect(res?.paymentUrl)
  const authToken = useCookie('authToken', {
    maxAge: (60 * 60 * 24 * 30 * 12),
    path: '/'
  });
  authToken.value = res?.token
  setTimeout(async () => {
    await getUserMe();
  }, 1000)
}

const fallbackImg = useAsset('/assets/images/png/default-imgs/product.png')
const currentAmount = ref(currentFastPayProductCount.value)

const totalPrice = computed(() => {
  const price = currentProduct.value?.discount_price ?? currentProduct.value?.price
  const min_payment = selectedPayInfo.value?.min_deposit_amount ?? 0
  const currentPrice = currentAmount.value * (price ?? 0)
  if (currentPrice >= min_payment) {
    return (currentPrice * (1 + ((selectedPayInfo.value?.percent_fee ?? 0) / 100)) + (selectedPayInfo.value?.fix_fee ?? 0))
  } else {
    return (min_payment * (1 + ((selectedPayInfo.value?.percent_fee ?? 0) / 100)) + (selectedPayInfo.value?.fix_fee ?? 0))
  }
})

const clampNode = ref()
const {clampButtonVisible, isClamp, isClampToglle} = useClamp(clampNode)

const emailValid = ref(false)

const emailValidation = async (node: FormKitNode) => {
  if (!node?.value) return false
  const exists = await checkIfEmailExists(String(node.value))
  emailValid.value = !!exists
  return emailValid.value
}
</script>

<style lang="scss" scoped>
.price-input-box {
  display: grid;
  grid-template-columns: 1fr auto;
}

.payments-chosen-price-container {
  display: flex;
  gap: 16px;
  align-items: center;

  .payments-chosen-price-container-item {
    display: flex;
    gap: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: rgba(29, 37, 74, 0.6);

    span {
      color: rgba(29, 37, 74, 1);
    }
  }

}

.fast-pay-form {
  :deep(.formkit-outer) {
    .formkit-messages:empty {
      display: none;
    }

    &[data-type="selectC"] {
      .formkit-select-prefix-icon {
        width: auto;
        height: 29px;
        margin: -4px 0 -4px;
        object-fit: contain;
        object-position: center;
      }

      .formkit-list-item-icon {
        height: 20px;
        width: auto;
        object-fit: contain;
        object-position: center;
      }

      .formkit-list-item-select,
      .formkit-list-item:hover {
        background: rgba(218, 72, 39, 0.2);
        color: #da4727;
      }

    }
  }
}

.divider {
  width: calc(100% + 80px);
  height: 1px;
  background: #d9d9dc;
  margin: 9px 0 22px -40px;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payments-box {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 10px;
}

.payments-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 80%;
  letter-spacing: -0.03em;
  color: #1d254a;
}

.payments-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 20px;
  background: #F1F1F4;
  box-shadow: 0px 4px 6px 0px rgba(77, 24, 13, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  color: #1d254a;
  letter-spacing: -0.03em;
  min-height: 50px;
  gap: 4px;

  &.active {
    border-color: rgba(218, 71, 39, 0.4);
    background-color: rgba(218, 71, 39, 0.15);
  }

  &:hover {
    border-color: rgba(218, 71, 39, 0.4);
    background-color: rgba(218, 71, 39, 0.15);
    transform: scale(1.1);
    cursor: pointer;
  }

  span {
    font-weight: 700;
  }

  img {
    height: 54px;
    object-fit: contain;
    object-position: center;
  }
}

.pay-modal-title-box {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  z-index: 1;
}

.payments-box-title {
  margin-top: 4px;
  font-weight: 600;
  font-size: 22px;
}

.scroll-customize {
  overflow: auto;
}

.pay-modal {
  :deep(.modal-content) {
    max-width: 673px;
    padding: 26px 40px 32px;
  }
}

.pay-modal-title {
  font-weight: 800;
  font-size: 28px;
  line-height: 80%;
  letter-spacing: -0.03em;
  color: #1d254a;
}

.payments-text {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 120%;
}

.card-product {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
  contain: paint;
  margin-top: 21px;
  gap: 10px;

  .img-box {
    display: flex;
    width: 106px;
    height: 106px;
    flex-shrink: 0;
    position: relative;
    container-type: size;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    overflow: hidden;

    img {
      position: sticky;
      top: 6px;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      align-self: center;

      @container (min-height:160px) {
        align-self: flex-start;
      }
    }
  }

  .text-box {
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: rgba(29, 37, 74, 0.7);
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      font-weight: 800;
      font-size: 17px;
      line-height: 120%;
      color: #1d254a;
    }

    .text-box-btns {
      display: flex;
      align-items: center;
      gap: 12px;

      .text-box-quantity {
        font-weight: 600;
        font-size: 13px;
        line-height: 80%;
        letter-spacing: -0.03em;
        color: #da4727;
      }
    }
  }

  .description {

    &.clamp {
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .button-clamp {
    color: #da4727;
    font-weight: 600;
    font-size: 10px;
    line-height: 80%;
    letter-spacing: -0.03em;
    cursor: pointer;
    box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
    background: rgba(218, 71, 39, 0.15);
    border: 1px solid rgba(218, 71, 39, 0.2);
    border-radius: 16px;
    padding: 3px 9px;
    width: fit-content;
  }

}

.dispute-plashka {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  border: 1px solid rgba(218, 71, 39, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
  background: rgba(218, 71, 39, 0.15);
  padding: 12px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 80%;
  letter-spacing: -0.03em;
  color: #da4727;
  margin-top: 18px;

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    p {
      font-weight: 400;
      font-size: 16px;
      text-wrap: wrap;
      padding-right: 10px;
      max-width: 65%;

      &:first-of-type {
        max-width: 30%;
      }
    }

  }

  img {
    width: 32px;
    height: 32px;
  }
}

.price-input-box {
  display: grid;
  grid-template-columns: 1fr 202px;
  align-items: flex-start;
  gap: 12px 16px;
  margin-top: 18px;

  :deep(.formkit-outer) {

    .formkit-label {
      color: rgba(40, 54, 71, 0.6);
      font-weight: 400;
      font-size: 17px;
      line-height: 135%;
    }

    &[data-type="counter"] {

      .formkit-decrement,
      .formkit-increment {
        width: 34px;
        height: 34px;
      }

      .formkit-input {
        padding: 10px 18px;
        height: 34px;
        font-size: 16px;
      }

      .formkit-label {
        margin-bottom: 3px;
      }
    }
  }

}

.price-box {
  font-size: 17px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: rgba(40, 54, 71, 0.6);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .text-box {
    display: flex;
    align-items: flex-end;
  }

  .price {
    font-weight: 700;
    font-size: 32px;
    line-height: 80%;
    color: #283647;
  }

  .old-price {
    font-size: 18px;
    line-height: 80%;
    text-decoration: line-through;
    color: #9eadbf;
    margin-left: 10px;
  }

  .discount {
    display: block;
    padding: 7px 6px 5px 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 29px;
    background: #da4727;
    font-weight: 700;
    font-size: 14px;
    line-height: 80%;
    color: #fff;
    margin-left: 8px
  }
}

.discounts {
  margin-top: 14px;
  padding-top: 20px;
  border-top: 1px solid #d9d9dc;

  .discounts-item {
    font-size: 18px;
    line-height: 80%;
    letter-spacing: -0.03em;
    color: #1d254a;

    .label,
    .value {
      padding-bottom: 16px;
    }

    &:last-child {

      .label,
      .value {
        padding-bottom: 0;
      }
    }

    .value {
      font-weight: 700;
      padding-left: 21px;
    }
  }
}

.button-box {
  display: flex;
  margin: 28px 0 16px;
  gap: 18px;

  .btn {
    width: 210px;
    text-align: center;
    padding: 16px 20px 14px;
    font-weight: 700;
    font-size: 22px;
    line-height: 80%;
    letter-spacing: -0.03em;
  }

  .btn-pay {
    width: auto;
    flex-grow: 1;
  }
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
}

.fast-pay-form {
  :deep(.formkit-outer) {
    &[data-type="toggleButtons"] {
      .formkit-inner {
        border: none;
        box-shadow: none;
      }

      .formkit-options {
        flex-wrap: wrap;
        padding: 0;
        gap: 8px;
        background: none;
      }

      .formkit-option {
        border-radius: 12px;
        box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1), inset 0 0 0 1px #cecdd6;
        background: #fff;
        padding: 8px 15px 8px 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 109%;
        color: #090630;
        transition: color, background, box-shadow, .3s ease;
        overflow: hidden;
        max-width: 230px;
      }

      .formkit-option-active {
        font-weight: 700;
        box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1), inset 0 0 0 2px rgba(218, 71, 39, 0.5);
        background: #ffd8cf;
        color: #da4727;
      }

      .formkit-option:hover {
        box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1), inset 0 0 0 2px rgba(218, 71, 39, 0.5);
        background: #ffd8cf;
        color: #da4727;
      }

      .formkit-select-prefix-icon {
        height: 25px;
      }
    }
  }
}

.lg-show {
  display: block;
}

.xs-show {
  display: none;
}

.email-valid-suffix {
  font-weight: 600;
  font-size: 13px;
  line-height: 80%;
  letter-spacing: -0.03em;
  text-align: right;
  color: #2BC070;
  width: fit-content;
  height: fit-content;
}

@media (max-width: 1023px) {
  .pay-modal {
    :deep(.modal-content) {
      max-width: 673px;
      padding: 10px 12px 12px;
    }
  }

  .divider {
    display: none;
  }

  .payments-chosen-price-container {
    gap: 4px;
    align-items: flex-start;
    gap: 33px;
    margin-top: 0;

    .payments-chosen-price-container-item {
      flex-direction: column;
      gap: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: rgba(29, 37, 74, 0.6);

      span {
        color: rgba(29, 37, 74, 1);
      }
    }

  }

  .pay-modal-title {
    font-size: 24px;
    margin-top: 8px;
  }

  .card-product {
    padding: 8px 8px 10px;
    gap: 10px;

    .img-box {
      container-type: normal;
      width: 80px;
      height: 80px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      flex-shrink: 0;
      overflow: hidden;
      margin: auto;
    }

    .text-box {
      padding: 0;

      .text-box-btns {
        gap: 10px;

        .text-box-quantity {
          font-size: 12px;
        }
      }
    }

  }

  .dispute-plashka {
    gap: 8px;
    border-radius: 12px;
    padding: 10px;
    font-size: 16px;
    margin-top: 15px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .price-input-box {
    grid-template-columns: 1fr;
    margin-top: 15px;
    gap: 20px;
  }

  .price-box {
    font-size: 16px;
    gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d9d9dc;
    margin-bottom: -12px;

    .price {
      font-size: 28px;
    }

    .old-price {
      font-size: 16px;
      margin-left: 3px;
    }

    .discount {
      padding: 5px 4px 3px 4px;
      font-size: 10px;
      margin-left: 6px
    }
  }

  .discounts {
    margin-top: 16px;

    .discounts-item {
      font-size: 16px;

      .label,
      .value {
        padding-bottom: 12px;
      }

      &:last-child {

        .label,
        .value {
          padding-bottom: 0;
        }
      }
    }
  }

  .form-inputs {
    margin-top: 23px;
  }

  .price-input-box-count {
    grid-row: 3;
  }

  .button-box {
    display: flex;
    flex-direction: column;
    margin: 20px 0 14px;
    gap: 8px;

    .btn {
      flex: 1;
      padding: 11px 22px 9px;
      font-size: 16px;
      display: none;
    }

    .btn-pay {
      display: flex;
    }
  }

  .lg-show {
    display: none;
  }

  .xs-show {
    display: block;
  }

}
</style>